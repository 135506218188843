import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';

const DownloadButtonComponent = ({ data }) => {
  const handleDownload = () => {
    // データをCSV形式のテキストに変換する
    const csvContent = "data:text/csv;charset=utf-8," + 
                       "DateTime,Accessibility Score,Best Practices Score,Performance Score,SEO Score\n" +
                       data.datetimes.map(datetime => 
                         `${datetime.times},${datetime.scores.accessibility_score},${datetime.scores.best_practices_score},${datetime.scores.performance_score},${datetime.scores.seo_score}`
                       ).join("\n");

    // ダウンロード用リンクを作成し、クリックする
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // ダウンロード後にリンクを削除
  };

  console.log(data);
  return (
    <div>
      <IconButton aria-label='Download data' icon={<DownloadIcon />} onClick={handleDownload} />
    </div>
  );
};

export default DownloadButtonComponent;
