import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const GraphComponent = ({ data }) => {
  const canvasRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const dates = [];
    const scores = {
      accessibility_score: [],
      best_practices_score: [],
      performance_score: [],
      seo_score: [],
    };

    data.datetimes.forEach(datetime => {
      const date = new Date(datetime.times);
      dates.push(date.toLocaleString());
      scores.accessibility_score.push(datetime.scores.accessibility_score);
      scores.best_practices_score.push(datetime.scores.best_practices_score);
      scores.performance_score.push(datetime.scores.performance_score);
      scores.seo_score.push(datetime.scores.seo_score);
    });

    const chartData = {
      labels: dates,
      datasets: [
        {
          label: 'Accessibility',
          data: scores.accessibility_score,
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
        {
          label: 'Best Practices',
          data: scores.best_practices_score,
          backgroundColor: 'rgba(255, 99, 132, 0.6)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
        {
          label: 'Performance',
          data: scores.performance_score,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
        {
          label: 'SEO',
          data: scores.seo_score,
          backgroundColor: 'rgba(255, 159, 64, 0.6)',
          borderColor: 'rgba(255, 159, 64, 1)',
          borderWidth: 1,
        },
      ],
    };

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (canvas) {
      canvas.width = 600;
      canvas.height = 250;

      chartInstance.current = new Chart(ctx, {
        type: 'bar',
        data: chartData,
      });
    }
  }, [data]);

  return (
    <div>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default GraphComponent;
