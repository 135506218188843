import React, { useEffect ,useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import GraphComponent from '../components/GraphComponent';
import { setData } from '../STORE/actions';
import DownloadButtonComponent from '../components/DownloadButtonComponent';
import DeleteCardComponent from '../components/DeleteCardComponent';
import { AuthContextConsumer } from '../contexts/AuthContext';
import TitleComponent from '../components/TitleComponent';

export const AnalysisPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector(state => state.data);
  const [targetData, setTargetData] = useState(null);

  // AuthContextConsumer からログインユーザ、ログイン・ログアウト処理取得
  const authContext = AuthContextConsumer();

  const getQueryParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };

  const id = getQueryParam('id');

  useEffect(() => {
    // loginUser の存在をフック内で確認する
    if (!authContext.loginUser) {
      console.error('No user data available');
      return; // ここで処理を中断
    }

    // loginUserが存在する場合にのみデストラクチャリング
    const { displayName, email, uid } = authContext.loginUser;
    const userPayload = {
      user: {
        displayName, email, uid
      }
    };

    if (!data || data === 'json_data') {
      console.log("Attempting to fetch data from server");
      fetch('https://www.pagespeedmonitor.jp/railsapp/users/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userPayload),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to create or retrieve user');
        }
        return response.json();
      })
      .then(result => {
        console.log('User created or retrieved:', result);
        if (Array.isArray(result.urls)) {
          dispatch(setData(result.urls));
        } else {
          console.error('Data format is not as expected:', result);
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
    } else {
      console.log('Data obtained from store');
      setTargetData(data[id]);
    }
  }, [data, dispatch, id, authContext]);  // authContextを依存配列に追加

  if (!authContext.loginUser) {
    return <div>No user data available. Please login.</div>;
  }

  return (
    <div>
      <div>
        {targetData && (
          <div>
            <TitleComponent/>
            <div className="downloadbutton_deletecard_component">
              <div className="deletecard_component">
                <DeleteCardComponent data={targetData}/>
              </div>
              <div className="downloadbutton_component">
                <DownloadButtonComponent data={targetData}/>
              </div>
              
            </div>
            <GraphComponent data={targetData} />
          </div>
        )}
      </div>
    </div>
  );
};