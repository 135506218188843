import React, { useState } from 'react';
import { IconButton, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const DeleteCardComponent = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();
  const navigate = useNavigate();

  const handleDelete = () => {
    setIsOpen(true);
  };

  const confirmDelete = () => {
    onClose();
    console.log('Deleting ID:', data.id); // 現在のidをコンソールに表示
    // IDを含むデータをAPIに送信する
    fetch('https://www.pagespeedmonitor.jp/railsapp/urls/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: data.id }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to delete data');
      }
      return response.json();
    })
    .then(result => {
      console.log('Data deleted:', result);
      if (result.success === 1) {
        // 一つ前のページに戻る
        navigate(-1);
      } else {
        console.error('Failed to delete data on server');
      }
    })
    .catch(error => {
      console.error('There was a problem with the delete operation:', error);
    });
  };

  return (
    <div>
      <IconButton aria-label='Delete data' icon={<DeleteIcon />} onClick={handleDelete} />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              データの削除
            </AlertDialogHeader>

            <AlertDialogBody>
              本当にこのデータを削除しますか？この操作は元に戻せません。
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                キャンセル
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                削除
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default DeleteCardComponent;