import { BrowserRouter, Link, Routes, Route } from "react-router-dom";
import { AuthContextProvider } from './contexts/AuthContext';
import { appRouter } from './pages/AppRouter';
import { AnalysisPage } from "./pages/AnalysisPage";
import { PlanPage } from "./pages/PlanPage";
import { CancellationPage } from "./pages/CancellationPage";
import { Home } from "./pages/Home";
import { LoginPage } from "./pages/LoginPage"; // 追加
import './App.css';

// ページ情報を定義して appRouter に設定
const pages = [
  { key: 'Home', path: '/', element: <Home /> },
  { key: 'AnalysisPage', path: '/AnalysisPage', element: <AnalysisPage /> },
  { key: 'PlanPage', path: '/PlanPage', element: <PlanPage /> },
  { key: 'CancellationPage', path: '/CancellationPage', element: <CancellationPage /> },
  { key: 'LoginPage', path: '/login', element: <LoginPage /> }, // 追加
];
const router = appRouter(pages);

function App() {
  return (
    <AuthContextProvider>
      {router.navbarLink}
      {/* 位置調整で main で括る */}
      <main>{router.browserRouter}</main>
    </AuthContextProvider>
  );}
export default App;