import React from 'react';
import { Box, Text, VStack, Button } from '@chakra-ui/react';
import axios from 'axios';

const styles = {
  planCard: {
    border: '1px solid #E2E8F0',
    borderRadius: '8px',
    padding: '24px',
    width: '100%',
    maxWidth: '300px',
    margin: '16px',
    boxShadow: 'md',
    textAlign: 'left',
    backgroundColor: '#ffffff',
    transition: 'transform 0.2s, box-shadow 0.2s',
  },
  currentPlanCard: {
    border: '2px solid #3182ce',
    backgroundImage: 'linear-gradient(to bottom, #3182ce, #63b3ed)',
    color: '#ffffff',
  },
  hoverEffect: {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '20px',
    marginBottom: '8px',
  },
  subtitle: {
    fontSize: '14px',
    color: '#718096',
    marginBottom: '16px',
  },
  price: {
    fontSize: '24px',
    color: '#2D3748',
    marginBottom: '16px',
  },
  currentPrice: {
    fontSize: '24px',
    color: '#ffffff',
    marginBottom: '16px',
  },
  featureList: {
    marginBottom: '16px',
  },
  featureItem: {
    fontSize: '14px',
    color: '#4A5568',
    marginBottom: '8px',
  },
  currentFeatureItem: {
    fontSize: '14px',
    color: '#ffffff',
    marginBottom: '8px',
  },
  button: {
    backgroundColor: '#3182ce',
    color: '#ffffff',
    padding: '12px 24px',
    borderRadius: '8px',
    cursor: 'pointer',
    border: 'none',
    width: '100%',
    transition: 'background-color 0.2s, transform 0.2s',
  },
  buttonHover: {
    backgroundColor: '#2B6CB0',
    transform: 'scale(1.05)',
  },
  currentButton: {
    backgroundColor: '#2B6CB0',
    color: '#ffffff',
    padding: '12px 24px',
    borderRadius: '8px',
    cursor: 'pointer',
    border: 'none',
    width: '100%',
    transition: 'background-color 0.2s, transform 0.2s',
  },
  currentButtonHover: {
    backgroundColor: '#e53e3e',
    transform: 'scale(1.05)',
  },
  priceDetails: {
    marginTop: '16px',
    fontSize: '14px',
    color: '#4A5568',
  },
  priceDetailItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  currentPriceDetailItem: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#ffffff',
  },
};

export const PlanCard = ({ title, subtitle, price, features, buttonText, priceDetails, isCurrent, uid, plan, price_id }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isButtonHovered, setIsButtonHovered] = React.useState(false);

  const handleButtonClick = async () => {
    try {
      if (isCurrent && isButtonHovered && title !== "FREE") {
        window.location.href = '/CancellationPage'; // プランの停止が押されたら遷移
        return;
      }
      console.log('price_id:', price_id);
      console.log('uid:', uid);
      console.log('plan:', plan);
      const response = await axios.post('https://www.pagespeedmonitor.jp/railsapp/create-checkout-session', {
        price_id,
        uid,
        plan,
      });
      const { url } = response.data;
      if (url) {
        window.location.href = url; // URLが返ってきたら自動的に遷移
      } else {
        alert('リクエストが送信されましたが、URLが返されませんでした');
      }
    } catch (error) {
      console.error('エラーが発生しました', error);
      alert('リクエストの送信に失敗しました');
    }
  };

  return (
    <div
      style={{
        ...styles.planCard,
        ...(isCurrent ? styles.currentPlanCard : {}),
        ...(isHovered ? styles.hoverEffect : {}),
        display: 'flex',
        flexDirection: 'column',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
        <Text style={isCurrent ? styles.currentPrice : styles.price}>{price}</Text>
        <VStack alignItems="flex-start" style={{ ...styles.featureList, flex: 1, overflowY: 'auto' }}>
          {features.map((feature, index) => (
            <Text key={index} style={isCurrent ? styles.currentFeatureItem : styles.featureItem}>✓ {feature}</Text>
          ))}
        </VStack>
        {priceDetails && (
          <div style={styles.priceDetails}>
            {priceDetails.map((detail, index) => (
              <div key={index} style={isCurrent ? styles.currentPriceDetailItem : styles.priceDetailItem}>
                <Text>{detail.amount} クレジット</Text>
                <Text>{detail.price}</Text>
              </div>
            ))}
          </div>
        )}
      </div>
      <button
        style={{
          ...(isCurrent ? styles.currentButton : styles.button),
          ...(isButtonHovered ? (isCurrent && title !== "FREE" ? styles.currentButtonHover : styles.buttonHover) : {}),
        }}
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
        onClick={handleButtonClick} // ボタンクリック時にリクエストを送信
      >
        {isCurrent && isButtonHovered && title !== "FREE" ? 'プランの停止' : isCurrent ? '適用中' : buttonText}
      </button>
    </div>
  );
};

export default PlanCard;
