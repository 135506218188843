import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, HStack, Spinner } from '@chakra-ui/react';
import TitleComponent from '../components/TitleComponent';
import PlanCard from '../components/PlanCardComponent';
import { AuthContextConsumer } from '../contexts/AuthContext';
import plansData from '../data/plans'; // プラン情報をインポート

// CSS styles
const styles = {
  planCard: {
    border: '1px solid #E2E8F0',
    borderRadius: 'md',
    padding: '16px',
    width: '100%',
    maxWidth: '300px',
    spacing: '16px',
    boxShadow: 'md',
    textAlign: 'left',
  },
  price: {
    fontSize: '24px',
    color: '#3182ce',
  },
  button: {
    backgroundColor: '#3182ce',
    color: '#ffffff',
    padding: '8px 16px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  container: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px', // タイトルとプランカード間の間隔を追加
  },
  hstack: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '16px', // カード間のスペースを追加
  },
};

// PlanPage Component
export const PlanPage = () => {
  const { loginUser, login, logout } = AuthContextConsumer();
  const [plans, setPlans] = useState(plansData); // 初期値としてインポートしたプラン情報を使用
  const [loading, setLoading] = useState(true); // ローディング状態を管理するステートを追加

  useEffect(() => {
    if (loginUser) {
      console.log(loginUser.uid);
      const uid = loginUser.uid; // loginUserからuidを取得
      const fetchCurrentPlan = async () => {
        const response = await fetch('https://www.pagespeedmonitor.jp/railsapp/users/current_plan', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });

        const data = await response.json();
        console.log(data); // 受け取ったデータを全て表示
        if (data.status === 'Success') {
          const updatedPlans = plans.map((plan, index) => ({
            ...plan,
            isCurrent: index === data.current_plan,
          }));
          setPlans(updatedPlans);
        } else {
          console.error('ユーザーが見つかりませんでした');
        }
        setLoading(false); // ローディング完了
      };
      fetchCurrentPlan();
    } else {
      setLoading(false); // loginUserがいない場合もローディング完了
    }
  }, [loginUser]);

  // loginUserが存在しない場合、スピナーを表示
  if (!loginUser) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  // 現在のプランがFREE以外の場合、FREEプランカードを表示しない
  const filteredPlans = plans.filter(plan => !(plan.title === "FREE" && !plans[0].isCurrent));

  return (
    <>
      <div className="title_component">
        <TitleComponent />
      </div>
      <div style={styles.container}>
        <Text fontSize="2xl" fontWeight="bold" textAlign="center" mb={4}>
          料金プラン
        </Text>
        <div style={styles.hstack}>
          {filteredPlans.map((plan, index) => (
            <PlanCard
              key={index}
              title={plan.title}
              price={plan.price}
              features={plan.features}
              buttonText={plan.buttonText}
              isCurrent={plan.isCurrent}
              uid={loginUser.uid} // uidをPlanCardに渡す
              plan={(() => {
                switch (plan.title) {
                  case "FREE":
                    return 0;
                  case "BASIC":
                    return 1;
                  case "STANDARD":
                    return 2;
                  case "PRO":
                    return 3;
                  default:
                    return plan.title;
                }
              })()} // プラン名をPlanCardに渡す
              price_id={plan.price_id} // price_idをPlanCardに渡す
            />
          ))}
        </div>
      </div>
    </>
  );
};