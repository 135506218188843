const plans = [
    {
      id: 0, // 変更
      title: "FREE",
      price: "¥0 月額",
      features: [
        "10 URL監視",
        "週次チェック",
        "1か月履歴",
      ],
      buttonText: "無料で開始",
      isCurrent: false,
      price_id: process.env.REACT_APP_FREE_PLAN_ID || 'default_free_plan_id',
    },
    {
      id: 1, // 変更
      title: "BASIC",
      price: "¥2,980 月額",
      features: [
        "50 URL監視",
        "週次チェック",
        "全サイトスキャン",
        "Slack通知",
        "メールアラート",
        "1か月履歴",
      ],
      buttonText: "利用開始",
      isCurrent: false,
      price_id: process.env.REACT_APP_BASIC_PLAN_ID || 'default_basic_plan_id',
    },
    {
      id: 2, // 変更
      title: "STANDARD",
      price: "¥9,800 月額",
      features: [
        "100 URL監視",
        "日次チェック",
        "全サイトスキャン",
        "Slack通知",
        "メールアラート",
        "6か月履歴",
      ],
      buttonText: "利用開始",
      isCurrent: false,
      price_id: process.env.REACT_APP_STANDARD_PLAN_ID || 'default_standard_plan_id',
    },
    {
      id: 3, // 変更
      title: "PRO",
      price: "¥29,800 月額",
      features: [
        "無制限 URL監視",
        "日次チェック",
        "全サイトスキャン",
        "Slack通知",
        "メールアラート",
        "1年履歴",
      ],
      buttonText: "利用開始",
      isCurrent: false,
      price_id: process.env.REACT_APP_PRO_PLAN_ID || 'default_pro_plan_id',
    },
  ];
  
  export default plans;