import React from 'react';
import { BrowserRouter, Link, Routes, Route } from "react-router-dom";

export default function ScorecardComponent({ itemkey, url, scores, onClick }) {
  const getColorForScore = (score) => {
    const hue = ((score / 100) * 120).toString(10);
    return `hsl(${hue}, 100%, 40%)`;
  };

  // URLをデコード
  const decodedUrl = decodeURIComponent(url);

  return (
    <button className="scorecard" onClick={onClick}>
      {/* デコードされたURLを表示 */}
      <a href={decodedUrl} className="scorecard-url" target="_blank" rel="noopener noreferrer">
        {decodedUrl}
      </a>
      <Link className="scorecard-scores" style={{ textDecoration: 'none' }} to={`/AnalysisPage/?id=${itemkey}`}>
        {scores && Object.entries(scores).map(([label, value], index) => (
          <div key={index} className="scorecard-score">
            <div className="scorecard-score-value" style={{ color: getColorForScore(value) }}>
              {value}
            </div>
            <div className="scorecard-score-label">
              {label.replace('_score', '')}
            </div>
          </div>
        ))}
      </Link>
    </button>
  );
}
