import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, Badge, List, ListItem, ListIcon, Flex, Spacer, Button, Divider, Skeleton } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import TitleComponent from '../components/TitleComponent';
import plans from '../data/plans';
import { AuthContextConsumer } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

// CancellationPage Component
export const CancellationPage = () => {
  const user = useSelector((state) => state.user);
  const { loginUser } = AuthContextConsumer();
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const response = await axios.post('https://www.pagespeedmonitor.jp/railsapp/users/current_plan', {
          uid: user.uid
        });
        if (response.data.status === 'Success') {
          setCurrentPlan(response.data.current_plan);
          setIsLoading(false);
          console.log('現在のプラン:', response.data.current_plan);
        }
      } catch (error) {
        console.error('プランの取得に失敗しました:', error);
        setIsLoading(false);
      }
    };

    if (user && user.uid) {
      fetchCurrentPlan();
    } else {
      console.error('ユーザー情報が取得できませんでした:', user ? user : 'null');
    }
  }, [user]);

  if (!loginUser) {
    return (
      <Box textAlign="center" mt={8}>
        <Text fontSize="xl" color="gray.500">ログインしてください。</Text>
      </Box>
    );
  }

  return (
    <>
      <TitleComponent />
      {isLoading ? (
        <Skeleton height="400px" my={8} mx="auto" borderRadius="lg" />
      ) : currentPlan !== null ? (
        <Box
          mt={8}
          p={8}
          borderRadius="lg"
          boxShadow="xl"
          bg="blue.500"
          color="white"
          maxW="600px"
          mx="auto"
        >
          <VStack spacing={6} align="start">
            <Flex w="100%" align="center">
              <Text fontSize="2xl" fontWeight="bold">
                現在のプラン: {plans.find(plan => plan.id === currentPlan).title}
              </Text>
              <Spacer />
              <Badge colorScheme="blue" fontSize="0.9em" px={3} py={1} borderRadius="full">
                アクティブ
              </Badge>
            </Flex>

            <Text fontSize="lg" color="blue.100">価格: {plans.find(plan => plan.id === currentPlan).price}</Text>

            <Divider borderColor="blue.300" />

            <List spacing={3}>
              {plans.find(plan => plan.id === currentPlan).features.map((feature, index) => (
                <ListItem key={index} fontSize="md">
                  <ListIcon as={CheckCircleIcon} color="blue.300" />
                  {feature}
                </ListItem>
              ))}
            </List>

            <Flex w="100%" justify="center" mt={6}>
              <Button 
                colorScheme="gray" 
                variant="solid" 
                size="lg" 
                onClick={() => navigate('/PlanPage')}
                mr={10}
              >
                戻る
              </Button>
              <Button 
                colorScheme="red" 
                variant="solid" 
                size="lg" 
                onClick={async () => {
                  try {
                    const response = await axios.post('https://www.pagespeedmonitor.jp/railsapp/cancel_subscription', {
                      uid: user.uid
                    });
                    const cancelAt = response.data.subscription.cancel_at;
                    const cancelDate = new Date(cancelAt * 1000).toLocaleString();
                    console.log('解約リクエストの結果:', response.data);
                    console.log('解約時刻:', cancelDate);
                    alert(`解約時刻: ${cancelDate}`);

                    // 新しいリクエストを追加
                    const updateResponse = await axios.post('https://www.pagespeedmonitor.jp/railsapp/users/update_cancel_at', {
                      uid: user.uid,
                      cancel_at: new Date(cancelAt * 1000).toISOString()
                    });
                    console.log('キャンセル時刻更新の結果:', updateResponse.data);
                  } catch (error) {
                    console.error('解約リクエストに失敗しました:', error);
                  }
                }}
              >
                解約する
              </Button>
            </Flex>
          </VStack>
        </Box>
      ) : (
        <Box textAlign="center" mt={8}>
          <Text fontSize="xl" color="gray.500">現在のプランが取得できませんでした。</Text>
        </Box>
      )}
    </>
  );
};
