import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, Button, Box, VStack, useColorModeValue } from '@chakra-ui/react';
import { AuthContextConsumer } from '../contexts/AuthContext';

export const LoginPage = () => {
  const { loginUser, login, logout } = AuthContextConsumer();
  const navigate = useNavigate();

  useEffect(() => {
    if (loginUser) {
      navigate('/'); // ログイン後に遷移するパス
    }
  }, [loginUser, navigate]);

  // Background and button color based on light mode only
  const bg = 'gray.100';
  const buttonBg = 'blue.500';
  const buttonHoverBg = 'blue.600';
  const cardBg = 'white';
  const textColor = 'gray.800';

  return (
    <Box height="100vh" bg={bg} position="relative">
      {/* Large Title */}
      <Text
        fontSize={{ base: '4xl', md: '6xl' }} // Adjusts size based on screen
        fontWeight="extrabold"
        color="blue.500"
        textAlign="center"
        position="absolute"
        top="15%" // Positioned slightly lower for better spacing
        left="50%"
        transform="translateX(-50%)"
        letterSpacing="wide"
      >
        PageSpeed Insights
      </Text>

      {/* Centered Login Box */}
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          p={10}
          borderRadius="2xl"
          boxShadow="2xl"
          bg={cardBg}
          maxW="md"
          w="100%"
          textAlign="center"
        >
          <VStack spacing={8}>
            <Text fontSize="lg" color={textColor} fontWeight="medium">
              Log in to access your account and insights.
            </Text>
            <Button
              colorScheme="blue"
              bg={buttonBg}
              size="lg"
              borderRadius="full"
              _hover={{ bg: buttonHoverBg }}
              onClick={loginUser ? logout : login}
              width="full"
            >
              {loginUser ? 'Logout' : 'Login'}
            </Button>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};
