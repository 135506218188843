import { createStore } from 'redux';
import rootReducer from './reducers';

// ストアの作成
const store = createStore(rootReducer);

export default store;


// <ファイルの役割>
// ・このファイルは、Redux ストアを作成します。ストアは、アプリケーション全体の状態を管理する場所です。
// ・通常、ルートリデューサーを使用してストアを作成します。これは、アプリケーション内のすべてのリデューサーを組み合わせたものです。
// ・ストアを作成した後は、必要に応じてプロバイダーコンポーネントでアプリケーションにストアを提供します。