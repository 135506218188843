import React, { useState } from 'react';
import { Button, Input, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Radio, RadioGroup, Stack, Select, useToast } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

export default function AddUrlComponent({ userdata, refreshScores }) {
  const { isOpen, onOpen, onClose: originalOnClose } = useDisclosure();
  const toast = useToast();
  const [url, setUrl] = useState('');
  const [frequency, setFrequency] = useState('daily');
  const [dayOfWeek, setDayOfWeek] = useState('');
  const [hour, setHour] = useState('');

  const planNames = ["Free", "Basic", "Standard", "Pro"];

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleFrequencyChange = (nextValue) => {
    setFrequency(nextValue);
    if (nextValue === 'daily') {
      setDayOfWeek('');
    }
  };

  const handleDayOfWeekChange = (event) => {
    setDayOfWeek(event.target.value);
  };

  const handleHourChange = (event) => {
    setHour(event.target.value);
  };

  const resetInputs = () => {
    setUrl('');
    setFrequency('daily');
    setDayOfWeek('');
    setHour('');
  };

  const onClose = () => {
    resetInputs();
    originalOnClose();
  };

  const canSubmit = () => {
    return url && hour && (frequency === 'daily' || (frequency === 'weekly' && dayOfWeek));
  };

  const handleSubmit = async () => {
    if (canSubmit()) {
      const requestData = {
        uid: userdata.uid,
        url: {
          url: url,
          period: frequency === 'daily' ? 'day' : 'week',
          minute_interval: null,
          hour_interval: null,
          time: `${hour}:00:00`,  // Always set minutes and seconds to 00
          date: null,
          day_of_week: dayOfWeek || null
        }
      };

      try {
        const response = await fetch('https://www.pagespeedmonitor.jp/railsapp/urls', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (response.status === 403) {
            console.error('Failed to submit URL:', errorData.error);
            console.log('User plan:', errorData.plan);  // ユーザーのプランをコンソールに表示

            // アラームを表示
            toast({
              title: "URL追加上限",
              description: `現在のプラン(${planNames[errorData.plan]})では、これ以上URLを追加できません。`,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            throw new Error('Something went wrong with the POST request');
          }
          return;
        }

        const data = await response.json();
        console.log("URL successfully added:", data);

        // URLが正常に追加された後にスコアデータをリフレッシュ
        refreshScores();
      } catch (error) {
        console.error('Failed to submit URL: ', error);
      } finally {
        onClose(); // Always close the modal, regardless of the result
      }
    }
  };

  return (
    <>
      <Button leftIcon={<AddIcon />} colorScheme='teal' variant='solid' onClick={onOpen}>
        Add
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a new URL</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter URL here"
              value={url}
              onChange={handleUrlChange}
            />
            <RadioGroup onChange={handleFrequencyChange} value={frequency} mt={4}>
              <Stack direction="row">
                <Radio value="daily">Daily</Radio>
                <Radio value="weekly">Weekly</Radio>
              </Stack>
            </RadioGroup>
            {frequency === 'weekly' && (
              <Select placeholder="Select day of the week" value={dayOfWeek} onChange={handleDayOfWeekChange} mt={4}>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
                <option value="Sunday">Sunday</option>
              </Select>
            )}
            <Input
              placeholder="Enter hour (HH)"
              type="number"
              min="0"
              max="23"
              value={hour}
              onChange={handleHourChange}
              mt={4}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={onClose}>Cancel</Button>
            <Button colorScheme='blue' onClick={handleSubmit} isDisabled={!canSubmit()}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
