import React, { useEffect, useState } from 'react';
import ScorecardComponent from './ScorecardComponent';
import { useDispatch } from 'react-redux';
import { setData } from '../STORE/actions';

export default function ScoreSlideComponent({ userdata, scores }) {
  const [height, setHeight] = useState(window.innerHeight);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Reduxストアにデータを保存
    dispatch(setData(scores));

    // コンポーネントがアンマウントされた時にイベントリスナーをクリーンアップ
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch, scores]);

  // 最新のスコアを取得する関数
  const getLatestScore = (datetimes) => {
    if (!Array.isArray(datetimes) || datetimes.length === 0) {
      return null;
    }
    return datetimes.sort((a, b) => {
      const timeA = new Date(a.times).getTime();
      const timeB = new Date(b.times).getTime();
      return timeB - timeA;
    })[0].scores;
  };

  return (
    <div style={{ overflow: 'auto', height: `${height - 200}px` }}>
      {scores.map((urlData, index) => (
        <div itemkey={index} className="scorecard_component">
          <ScorecardComponent itemkey={index} url={urlData.url} scores={getLatestScore(urlData.datetimes)} userdata={userdata} />
        </div>
      ))}
    </div>
  );
}
