// アクションのタイプ定義
export const SET_DATA = 'SET_DATA';

// アクションクリエーター
export const setData = (data) => ({
  type: SET_DATA,
  payload: data,
});

// <ファイルの役割>
// ・このファイルは、アプリケーション内で何が起こるかを説明する「行動」を定義します。例えば、「新しいデータを追加する」、「データを更新する」などです。
// ・アクションは、その行動に関連するデータを含むオブジェクトです。これらのオブジェクトは、アプリケーションのどこからでも参照できます。

export const SET_USER = 'SET_USER';

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});