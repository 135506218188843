import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import TitleComponent from "../components/TitleComponent";
import ScoreSlideComponent from "../components/ScoreSlideComponent";
import HamburgerMenuComponent from "../components/HamburgerMenuComponent";
import { AuthContextConsumer } from '../contexts/AuthContext';
import { Text } from '@chakra-ui/react';
import AddUrlComponent from '../components/AddUrlComponent';

export const Home = () => {
  const { loginUser, login, logout } = AuthContextConsumer();
  const [scores, setScores] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (loginUser) {
      fetchUserScores();
    } else {
      navigate('/login');
    }
  }, [loginUser, navigate]);

  const fetchUserScores = async () => {
    const { displayName, email, uid } = loginUser;
    const userPayload = {
      user: {
        displayName, email, uid
      }
    };

    try {
      const response = await fetch('https://www.pagespeedmonitor.jp/railsapp/users/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userPayload),
      });

      if (!response.ok) {
        throw new Error('Failed to create or retrieve user');
      }

      const result = await response.json();
      if (Array.isArray(result.urls)) {
        setScores(result.urls);
      } else {
        console.error('Data format is not as expected:', result);
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <>
      <div className="title_component">
        <TitleComponent />
      </div>
      <div className='humburgermenu_user_component'>
        <div className="addurlcomponent_component">
          <AddUrlComponent userdata={loginUser} refreshScores={fetchUserScores} />
        </div>
        <div className="hamburgermenu_component">
          <HamburgerMenuComponent userdata={loginUser} />
        </div>
      </div>
      <div className="scoreslide_component">
        <ScoreSlideComponent userdata={loginUser} scores={scores} />
      </div>
    </>
  );
};
