import { SET_DATA, SET_USER } from './actions';

// 初期状態
const initialState = {
  data: "json_data",
  user: null,
};

// リデューサー
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;


// <ファイルの役割>
// ・このファイルは、アプリケーションの状態がどのように変化するかを定義します。これを行う関数を「リデューサー」と呼びます。
// ・リデューサーは、アクションの種類に応じて新しい状態を生成します。つまり、アクションが発生したときに、リデューサーはそのアクションに基づいて新しい状態を計算します。
// ・リデューサーは、アプリケーション内の特定のデータストア（または「スライス」と呼ばれることもあります）の状態を更新します。
