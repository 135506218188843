import React, { useState, useEffect } from 'react';
import { AuthContextConsumer } from '../contexts/AuthContext';

export default function HamburgerMenuComponent({ userdata }) {
  const [isOpen, setIsOpen] = useState(false);

  // AuthContextConsumer からログインユーザ、ログイン・ログアウト処理取得
  const { loginUser, login, logout } = AuthContextConsumer();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // メニューが開いている場合かつクリックされた場所がメニュー内でない場合にメニューを閉じる
      if (isOpen && !event.target.closest('.hamburger-menu-container')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]); // isOpenの状態に変更があった場合のみeffectが再実行される

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="hamburger-menu-container">
      <div className="hamburger-menu" onClick={toggleMenu}>
        <span className={`hamburger-bar ${isOpen ? 'open' : ''}`}></span>
        <span className={`hamburger-bar ${isOpen ? 'open' : ''}`}></span>
        <span className={`hamburger-bar ${isOpen ? 'open' : ''}`}></span>
      </div>

      <nav className={`menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="/">Warning</a></li>
          <li><a href="/contact">Contact</a></li>
          <li><a href="/PlanPage">Plan</a></li>
          <li>
            <a onClick={loginUser ? logout : login}>
              logout
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
